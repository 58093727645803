import { ua } from '@florencecard-lib/ua';

export interface Storage {
  get(key: string): string | null;
  set(key: string, value: string): void;
  delete(key: string): void;
  clear(): void;
}

class MemoryStorage implements Storage {
  private data = new Map<string, string>();

  get(key: string) {
    return this.data.get(key) ?? null;
  }

  set(key: string, value: string) {
    this.data.set(key, value);
  }

  delete(key: string) {
    this.data.delete(key);
  }

  clear() {
    this.data.clear();
  }
}

class LocalStorage implements Storage {
  private readonly storage: typeof window.localStorage;

  static canUse() {
    const testKey = '__wm_local_storage_test__';

    try {
      window.localStorage.setItem(testKey, '1');
      window.localStorage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }

  constructor() {
    this.storage = window.localStorage;
  }

  get(key: string) {
    return this.storage.getItem(key);
  }

  set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  delete(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}

class SessionStorage implements Storage {
  private readonly storage: typeof window.sessionStorage;

  static canUse() {
    const testKey = '__wm_session_storage_test__';

    try {
      window.sessionStorage.setItem(testKey, '1');
      window.sessionStorage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  }

  constructor() {
    this.storage = window.sessionStorage;
  }

  get(key: string) {
    return this.storage.getItem(key);
  }

  set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  delete(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}

export function canUseLocalStorage() {
  return LocalStorage.canUse();
}

export function canUseSessionStorage() {
  return SessionStorage.canUse();
}

let cachedLocalStorage: Storage | null = null;
let cachedSessionStorage: Storage | null = null;

export function createLocalStorage() {
  if (!ua.isBrowser) {
    return new MemoryStorage();
  }

  if (cachedLocalStorage !== null) {
    return cachedLocalStorage;
  }

  cachedLocalStorage = LocalStorage.canUse() ? new LocalStorage() : new MemoryStorage();

  return cachedLocalStorage;
}

export function createSessionStorage() {
  if (!ua.isBrowser) {
    return new MemoryStorage();
  }

  if (cachedSessionStorage !== null) {
    return cachedSessionStorage;
  }

  cachedSessionStorage = SessionStorage.canUse() ? new SessionStorage() : new MemoryStorage();

  return cachedSessionStorage;
}
