import type { WithCustomElement } from '@florencecard-components/as';
import type { ExtendBoxCSSProperties } from '@florencecard-components/box';
import { Box } from '@florencecard-components/box';
import { buttonReset } from '@florencecard-components/mixins';
import type { ResponsiveValue } from '@florencecard-components/responsive';
import { useResponsive } from '@florencecard-components/responsive';
import React, { ElementType, forwardRef, Ref } from 'react';

type CSSPixelValue = string | number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BoxComp = Box as any;

export type ButtonCSSProperties = ExtendBoxCSSProperties<{
  size: ResponsiveValue<CSSPixelValue | undefined>;
}>;

export type ButtonProps<Element extends ElementType = 'button'> = WithCustomElement<
  Element,
  ButtonCSSProperties
>;

function _Button<Element extends ElementType = 'button'>(
  {
    as = 'button' as Element,
    size,
    display = 'inline-block',
    position = 'relative',
    padding = 0,
    paddingLeft = 8,
    paddingRight = 8,
    backgroundColor = 'transparent',
    fontWeight = 500,
    textAlign = 'center',
    textDecoration = 'none',
    whiteSpace = 'nowrap',
    lineHeight,
    color,
    children,
    ...props
  }: ButtonProps<Element>,
  ref: Ref<HTMLElement>,
) {
  const mq = useResponsive();

  return (
    <BoxComp
      ref={ref}
      as={as}
      display={display}
      position={position}
      padding={padding}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      height={size}
      backgroundColor={backgroundColor}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      whiteSpace={whiteSpace}
      textAlign={textAlign}
      color={color}
      textDecoration={textDecoration}
      _css={[
        buttonReset,
        mq({
          userSelect: 'none',
          cursor: 'pointer',
          outline: 'none',
          verticalAlign: 'baseline',
          '&:disabled': {
            opacity: '0.35',
          },
        }),
      ]}
      {...props}
    >
      {children}
    </BoxComp>
  );
}

export const Button = forwardRef(_Button);
