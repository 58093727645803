import type { AnyObject } from '@florencecard-lib/type-utils';
import { createLocalStorage, Storage } from '~/utils/storage';

interface CreatePersistenceOptions {
  /**
   * @default sessionStorage
   */
  provider?: Storage;
}

export interface Persistence<T extends Record<string, string>> {
  get(key: keyof T): string | null;
  set(key: keyof T, value: string | null | undefined): void;
  delete(key: keyof T): void;
}

export function createPersistence<T extends AnyObject>(
  keyPrefix: string,
  options?: CreatePersistenceOptions,
): Persistence<T> {
  const { provider = createLocalStorage() } = options ?? {};

  const getKey = (key: keyof T) => {
    return `${keyPrefix}/${key}`;
  };

  return {
    get(key) {
      return provider.get(getKey(key));
    },
    set(key, value) {
      if (value != null) {
        provider.set(getKey(key), value);
      }
    },
    delete(key) {
      provider.delete(getKey(key));
    },
  };
}
