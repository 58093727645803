import axios, { AxiosError } from 'axios';
import { authPersistence } from '~/persistences/auth';

export const baseApiUrl =
  process.env.NODE_ENV === 'production' ? 'https://api.florencecard.me' : 'http://localhost:5000';

export const api = axios.create({
  baseURL: baseApiUrl,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const accessToken = authPersistence.get('accessToken');

  if (accessToken != null && config.headers?.Authorization == null) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return config;
});

export interface ApiError {
  code: string;
  message?: string;
}

export enum ApiErrorCodes {
  청첩장을_찾을_수_없음 = 'letters.letterNotFound',
  인증에_실패함 = 'auth.userUnauthorized',
  OAUTH_필수정보_누락됨 = 'auth.oauth.missingRequiredFields',
  OAUTH_상태토큰이_없거나_만료됨 = 'auth.oauth.stateTokenNotExistsOrExpired',
  OAUTH_사용자가_로그인_취소함 = 'auth.oauth.accessDenied',
  OAUTH_인증서버_오류 = 'auth.oauth.authorizeServerError',
  OAUTH_사용자_이메일이_일치하지_않음 = 'auth.oauth.userEmailNotMatched',
  사용자_이메일_중복됨 = 'users.duplicatedEmail',
  청첩장이_서비스_중이_아님 = 'letters.letterIsNotInService',
  사용자_비밀번호_확인에_실패함 = 'users.userPasswordNotMatched',
}

export function isAxiosError(error: unknown): error is AxiosError {
  return error != null && typeof error === 'object' && (error as AxiosError)?.isAxiosError === true;
}

function isErrorLike(error: unknown): error is Error {
  return error != null && error instanceof Error;
}

export function isApiError(error: unknown): error is ApiError {
  return error != null && typeof error === 'object' && (error as ApiError).code != null;
}

export function queryApiErrorCode(error: unknown) {
  if (isAxiosError(error) && error.response != null) {
    return isApiError(error.response?.data) ? error.response.data.code : undefined;
  }

  return undefined;
}

export function queryErrorMessage(
  error: unknown,
  fallbackMessage = '알 수 없는 오류가 발생하였습니다. 잠시 후에 다시 시도해주세요.',
) {
  if (isAxiosError(error) && error.response != null) {
    if (isApiError(error.response?.data)) {
      return error.response?.data?.message ?? fallbackMessage;
    }

    return error.message ?? fallbackMessage;
  }

  if (isErrorLike(error)) {
    return error.message ?? fallbackMessage;
  }

  return typeof error === 'string' ? error : fallbackMessage;
}
