import { createPersistence } from '~/utils/persistence';
import { createLocalStorage } from '~/utils/storage';

export interface AuthPersistenceData {
  accessToken: string;
}

export const authPersistence = createPersistence<AuthPersistenceData>('@@wm/auth', {
  provider: createLocalStorage(),
});
