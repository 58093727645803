/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Interpolation } from '@emotion/react';
import type { WithCustomElement } from '@florencecard-components/as';
import { ResponsiveValue, useResponsive } from '@florencecard-components/responsive';
import type { AnyObject } from '@florencecard-lib/type-utils';
import React, { CSSProperties, ElementType, forwardRef, Ref } from 'react';

export interface BoxCSSProperties {
  display?: ResponsiveValue<CSSProperties['display']>;
  width?: ResponsiveValue<CSSProperties['width']>;
  minWidth?: ResponsiveValue<CSSProperties['minWidth']>;
  maxWidth?: ResponsiveValue<CSSProperties['maxWidth']>;
  height?: ResponsiveValue<CSSProperties['height']>;
  minHeight?: ResponsiveValue<CSSProperties['minHeight']>;
  maxHeight?: ResponsiveValue<CSSProperties['maxHeight']>;
  padding?: ResponsiveValue<CSSProperties['padding']>;
  paddingTop?: ResponsiveValue<CSSProperties['paddingTop']>;
  paddingRight?: ResponsiveValue<CSSProperties['paddingRight']>;
  paddingBottom?: ResponsiveValue<CSSProperties['paddingBottom']>;
  paddingLeft?: ResponsiveValue<CSSProperties['paddingLeft']>;
  margin?: ResponsiveValue<CSSProperties['margin']>;
  marginTop?: ResponsiveValue<CSSProperties['marginTop']>;
  marginRight?: ResponsiveValue<CSSProperties['marginRight']>;
  marginBottom?: ResponsiveValue<CSSProperties['marginBottom']>;
  marginLeft?: ResponsiveValue<CSSProperties['marginLeft']>;
  overflow?: ResponsiveValue<CSSProperties['overflow']>;
  overflowX?: ResponsiveValue<CSSProperties['overflowX']>;
  overflowY?: ResponsiveValue<CSSProperties['overflowY']>;
  zIndex?: ResponsiveValue<CSSProperties['zIndex']>;
  flex?: ResponsiveValue<CSSProperties['flex']>;
  color?: ResponsiveValue<CSSProperties['color']>;
  opacity?: ResponsiveValue<CSSProperties['opacity']>;
  position?: ResponsiveValue<CSSProperties['position']>;
  top?: ResponsiveValue<CSSProperties['top']>;
  right?: ResponsiveValue<CSSProperties['right']>;
  bottom?: ResponsiveValue<CSSProperties['bottom']>;
  left?: ResponsiveValue<CSSProperties['left']>;
  border?: ResponsiveValue<CSSProperties['border']>;
  borderWidth?: ResponsiveValue<CSSProperties['borderWidth']>;
  borderStyle?: ResponsiveValue<CSSProperties['borderStyle']>;
  borderColor?: ResponsiveValue<CSSProperties['borderColor']>;
  borderTop?: ResponsiveValue<CSSProperties['borderTop']>;
  borderTopWidth?: ResponsiveValue<CSSProperties['borderTopWidth']>;
  borderTopStyle?: ResponsiveValue<CSSProperties['borderTopStyle']>;
  borderTopColor?: ResponsiveValue<CSSProperties['borderTopColor']>;
  borderRight?: ResponsiveValue<CSSProperties['borderRight']>;
  borderRightWidth?: ResponsiveValue<CSSProperties['borderRightWidth']>;
  borderRightStyle?: ResponsiveValue<CSSProperties['borderRightStyle']>;
  borderRightColor?: ResponsiveValue<CSSProperties['borderRightColor']>;
  borderBottom?: ResponsiveValue<CSSProperties['borderBottom']>;
  borderBottomWidth?: ResponsiveValue<CSSProperties['borderBottomWidth']>;
  borderBottomStyle?: ResponsiveValue<CSSProperties['borderBottomStyle']>;
  borderBottomColor?: ResponsiveValue<CSSProperties['borderBottomColor']>;
  borderLeft?: ResponsiveValue<CSSProperties['borderLeft']>;
  borderLeftWidth?: ResponsiveValue<CSSProperties['borderLeftWidth']>;
  borderLeftStyle?: ResponsiveValue<CSSProperties['borderLeftStyle']>;
  borderLeftColor?: ResponsiveValue<CSSProperties['borderLeftColor']>;
  borderRadius?: ResponsiveValue<CSSProperties['borderRadius']>;
  borderTopLeftRadius?: ResponsiveValue<CSSProperties['borderTopLeftRadius']>;
  borderTopRightRadius?: ResponsiveValue<CSSProperties['borderTopRightRadius']>;
  borderBottomRightRadius?: ResponsiveValue<CSSProperties['borderBottomRightRadius']>;
  borderBottomLeftRadius?: ResponsiveValue<CSSProperties['borderBottomLeftRadius']>;
  background?: ResponsiveValue<CSSProperties['background']>;
  backgroundImage?: ResponsiveValue<CSSProperties['backgroundImage']>;
  backgroundSize?: ResponsiveValue<CSSProperties['backgroundSize']>;
  backgroundColor?: ResponsiveValue<CSSProperties['backgroundColor']>;
  backgroundPosition?: ResponsiveValue<CSSProperties['backgroundPosition']>;
  backgroundRepeat?: ResponsiveValue<CSSProperties['backgroundRepeat']>;
  flexDirection?: ResponsiveValue<CSSProperties['flexDirection']>;
  alignItems?: ResponsiveValue<CSSProperties['alignItems']>;
  justifyContent?: ResponsiveValue<CSSProperties['justifyContent']>;
  flexWrap?: ResponsiveValue<CSSProperties['flexWrap']>;
  fontSize?: ResponsiveValue<CSSProperties['fontSize']>;
  fontWeight?: ResponsiveValue<CSSProperties['fontWeight']>;
  textAlign?: ResponsiveValue<CSSProperties['textAlign']>;
  textDecoration?: ResponsiveValue<CSSProperties['textDecoration']>;
  lineHeight?: ResponsiveValue<CSSProperties['lineHeight']>;
  whiteSpace?: ResponsiveValue<CSSProperties['whiteSpace']>;
  wordWrap?: ResponsiveValue<CSSProperties['wordWrap']>;
  wordBreak?: ResponsiveValue<CSSProperties['wordBreak']>;
  overflowWrap?: ResponsiveValue<CSSProperties['overflowWrap']>;
  textOverflow?: ResponsiveValue<CSSProperties['textOverflow']>;
  cursor?: ResponsiveValue<CSSProperties['cursor']>;
  listStyle?: ResponsiveValue<CSSProperties['listStyle']>;
  order?: ResponsiveValue<CSSProperties['order']>;
  pointerEvents?: ResponsiveValue<CSSProperties['pointerEvents']>;
  boxShadow?: ResponsiveValue<CSSProperties['boxShadow']>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ExtendBoxCSSProperties<T extends unknown = {}> = Omit<BoxCSSProperties, keyof T> & T;

const boxCSSPropertyKeys: Array<keyof BoxCSSProperties> = [
  'display',
  'width',
  'minWidth',
  'maxWidth',
  'height',
  'minHeight',
  'maxHeight',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'overflow',
  'overflowX',
  'overflowY',
  'zIndex',
  'flex',
  'color',
  'opacity',
  'position',
  'top',
  'right',
  'bottom',
  'left',
  'border',
  'borderWidth',
  'borderStyle',
  'borderColor',
  'borderTop',
  'borderTopWidth',
  'borderTopStyle',
  'borderTopColor',
  'borderRight',
  'borderRightWidth',
  'borderRightStyle',
  'borderRightColor',
  'borderBottom',
  'borderBottomWidth',
  'borderBottomStyle',
  'borderBottomColor',
  'borderLeft',
  'borderLeftWidth',
  'borderLeftStyle',
  'borderLeftColor',
  'borderRadius',
  'borderTopRightRadius',
  'borderTopRightRadius',
  'borderBottomRightRadius',
  'borderBottomLeftRadius',
  'background',
  'backgroundImage',
  'backgroundPosition',
  'backgroundSize',
  'backgroundColor',
  'backgroundRepeat',
  'flexDirection',
  'alignItems',
  'justifyContent',
  'flexWrap',
  'fontSize',
  'fontWeight',
  'textAlign',
  'textDecoration',
  'lineHeight',
  'whiteSpace',
  'wordWrap',
  'wordBreak',
  'overflowWrap',
  'textOverflow',
  'cursor',
  'listStyle',
  'order',
  'pointerEvents',
  'boxShadow',
];

export type BoxProps<Element extends ElementType = 'div'> = WithCustomElement<
  Element,
  BoxCSSProperties
>;

function _Box<Element extends ElementType = 'div'>(
  {
    as = 'div',
    children,
    _css,
    ...props
  }: BoxProps<Element> & {
    _css?: Array<Interpolation<any>>;
  },
  ref: Ref<HTMLElement>,
) {
  const Component = as as any;
  const mq = useResponsive();
  const { cssProps, otherProps } = Object.entries(props).reduce(
    (val, [key, value]) => {
      if (boxCSSPropertyKeys.some((x) => x === key)) {
        val.cssProps[key] = value;
      } else {
        val.otherProps[key] = value;
      }

      return val;
    },
    {
      cssProps: {} as AnyObject,
      otherProps: {} as AnyObject,
    },
  );

  return (
    <Component ref={ref} css={[...(_css ?? []), mq(cssProps)]} {...otherProps}>
      {children}
    </Component>
  );
}

export const Box = forwardRef(_Box);
